import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { graphql } from "gatsby"
import { BrowserRouter as Router, Route } from "react-router-dom"
import SEO from "../components/seo"
import arrowDown from "./../images/icons/arrowDown.png"
import Footer from "../components/Footer"
import Main from "../components/TheBirraBehind/Main"
import styles from "./../scss/pages/_thebirrabehind.module.scss"
import Success from "../components/TheBirraBehind/Success"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import MapSection from "../components/TheBirraBehind/MapSection"
import Backtoliveuy from "../components/TheBirraBehind/uy/uy"
import { useContext } from "react"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

export default function Thebirrabehindthemusic({ location, data }) {
  const [age, setAge] = useState(true)
  const [step, setStep] = useState("")
  const dispatch = useContext(GlobalDispatchContext)
  const provincias = data.allProvinceEvents.edges
  const venues = data.allVenues.edges
  
  dispatch({
    type:'ADD_VENUES',
    venues,
  })

  let params = new URLSearchParams(location.search)
  const eventId = params.get("eid")

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "La musica en tus manos",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/lamusicaentusmanos",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: step ? `step_${step}` : "",
    })
  }, [step])


  useEffect(() => {
    // console.log("ok!")
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    // console.log("Cookie",cookie)
    if (cookie || local) setAge(false)
  }, [])

  return !age ? (
    <Router>
      <Route exact path="/lamusicaentusmanos">
        <div style={{ overflowX: "hidden" }}>
          <SEO title="The Birra behind the music" />
          <Container fluid className="m-0 p-0">
            {/*<section>*/}
            {/*  <Main eventId={eventId}/>*/}
            {/*</section>*/}
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#MapSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src={arrowDown} className={styles.ArrowDownSection} />
                </a>
              </Col>
            </Row>
            <section id="MapSection">
              <MapSection provincesEvents={provincias} setStep={setStep} eventId={eventId} venues={venues} location={location}/>
            </section>

            <Footer />
          </Container>
        </div>
      </Route>
      <Route path="/lamusicaentusmanos/success">
        <Success />
      </Route>
      <Route path="/lamusicaentusmanos/uy">
        <Backtoliveuy />
      </Route>
    </Router>
  ) : (
    <AgeGate path={location?.pathname + location?.search} />
  )
}

export const data = graphql`
  query thebirrabehindthemusic {
    allProvinceEvents(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          index
          address
          left
          name
          province
          schedule
          top
          url
          visible
        }
      }
    }

    allVenues(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          iconUrl
          location
          left
          leftMobile
          top
          topMobile
          visible
        }
      }
    }

  }
`
