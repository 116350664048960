import React from "react"
import styles from "../../scss/components/TheBirraBehind/_Map.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

function LogoImageLolla({ isLolla }) {
  const data = useStaticQuery(
    graphql`
      query {
        lollaImage: file(base: { eq: "lolla.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        muteImage: file(base: { eq: "mute.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allFile(
          filter: { relativeDirectory: { eq: "../../images/EventosLollaMute" } }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  const background = "../../images/EventosLollaMute/lollapalooza-logo.svg"

  return (
    <Img
      fluid={
        isLolla
          ? data.lollaImage.childImageSharp.fluid
          : data.muteImage.childImageSharp.fluid
      }
      loading="eager"
      className={isLolla ? styles.lollaIcon : styles.muteIcon}
    />
    // <StaticImage
    //   src={background}
    //   className={isLolla ? styles.lollaIcon : styles.muteIcon}
    //   loading="eager"
    //   alt="icon"
    //   placeholder="blurred"
    // />
  )
}

export default LogoImageLolla
